import React from "react";
import { FaFacebookF , FaInstagram, FaLinkedinIn , FaTwitter } from "react-icons/fa";

let data = [
    {
        images: '01',
        title: 'Daniel Anca',
        designation: 'Videographer/Photographer',
        socialNetwork: [           
            {
                icon: <FaInstagram />,
                url: 'http://instagram.com/danianca94'
            },
        ]
    },
    {
        images: '02',
        title: 'Estera Anca',
        designation: 'Photographer',
        socialNetwork: [
            {
                icon: <FaInstagram />,
                url: 'http://instagram.com/esteraa.anca'
            },
        ]
    },   
];

export default data;