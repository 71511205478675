import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import { useParams } from "react-router-dom";
import Footer from "../component/footer/Footer";
import "react-image-lightbox/style.css";

import styles from "./Portfolio.module.scss";
import { getPortofolioJSON } from "../utils/portfolioData";
import parse from "html-react-parser";
import data from "../blocks/team/data";
import TeamTwo from "../blocks/team/TeamTwo.jsx";

const PortfolioDetails = () => {
  let params = useParams();
  let projectName = params.projectName;
  const [isOpen, setIsOpen] = useState(false);
  const [dataPortofolio, setDataPortofolio] = useState(null);

  useEffect(() => {
    getPortofolioJSON().then((dataBe) => {
      setDataPortofolio(dataBe);
    });
  }, []);

  useEffect(() => {
    console.log("DATA PORTOFOLIO IS:", dataPortofolio);
  }, [dataPortofolio]);

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <PageHelmet pageTitle={dataPortofolio ? dataPortofolio.RO[projectName].title : ""} />
      <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
      {/* Start Breadcrump Area */}
      <div className={`rn-page-title-area pt--120 pb--190 bg_image bg_image--daniContact`} data-black-overlay="7">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  {dataPortofolio != null ? dataPortofolio.RO[projectName].title : "..."}
                </h2>
                <p>{dataPortofolio != null ? dataPortofolio.RO[projectName].description : "Incarcam datele..."}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}
      {/* Start Portfolio Details */}
      {dataPortofolio && (
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    <h2>{dataPortofolio.RO[projectName].post.miniTitle}</h2>
                    <p className="subtitle">{parse(dataPortofolio.RO[projectName].post.description)}</p>
                    <p>{parse(dataPortofolio.RO[projectName].post.other)}</p>
                    <p>
                      {dataPortofolio.RO[projectName].post.hasOwnProperty("address")
                        ? dataPortofolio.RO[projectName].post.address
                        : ""}
                    </p>
                    <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <span>Tipul proiectului</span>
                        <h4>{dataPortofolio.RO[projectName].category}</h4>
                      </div>

                      <div className="port-view">
                        <span>Data</span>
                        <h4>{dataPortofolio.RO[projectName].datePosted}</h4>
                      </div>
                    </div>
                  </div>
                  <div className={"portfolio-thumb-inner " + styles.ownStyleMediaContainer}>
                    {dataPortofolio.RO[projectName].media.hasOwnProperty("video") && (
                      <div className=" marginerMiddle thumb position-relative mb--30 ">
                        <img
                          src={dataPortofolio.RO[projectName].media.video.video1}
                          alt="Portfolio Images"
                          loading="lazy"
                          className={styles.grayThumbnail}
                        />
                        <ModalVideo
                          channel="youtube"
                          isOpen={isOpen}
                          videoId={`${dataPortofolio.RO[projectName].media.video.youtubeID}`}
                          onClose={openModal}
                        />
                        <button className="video-popup position-top-center" onClick={openModal}>
                          <span className="play-icon"></span>
                        </button>
                      </div>
                    )}
                    <div
                      className={
                        dataPortofolio.RO[projectName].media.picSettings.desktop == "3"
                          ? styles.theCardsThree
                          : dataPortofolio.RO[projectName].media.picSettings.desktop == "1"
                          ? styles.theCardsOne
                          : styles.theCards
                      }
                    >
                      {Object.values(dataPortofolio.RO[projectName].media.pictures).map((itemSrc) => (
                        <div className={styles.theCardsItem}>
                          <img className={styles.picItem} src={itemSrc} loading="lazy" alt="portfolio image" />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* End Portfolio Details */}
      {/* Start Related Work */}
      {dataPortofolio && (
        <div className="portfolio-related-work pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className={"theme-color font--27 fontWeight600 " + styles.textToView}>Alte proiecte</span>
                  <h2 className={"font--27 fontWeight600 " + styles.textToView}>realizate</h2>
                </div>
              </div>
            </div>

            <div className="row mt--10">
              {Object.values(dataPortofolio.RO).map((item) =>
                item !== dataPortofolio.RO[projectName] ? (
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="related-work text-center mt--30 ">
                      <div className={"thumb "}>
                        <a href={`/proiect/${item.link}`}>
                          <img src={item.image} loading="lazy" alt="Portfolio-images" />
                        </a>
                      </div>
                      <div className="inner">
                        <h4>
                          <a href={`/proiect/${item.link}`}>{item.title}</a>
                        </h4>
                        <span className="category">{item.category}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
            </div>
          </div>
        </div>
      )}
      {/* End Related Work */}
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      
       {/* Start Team Area--SuryAaa  */}
       <div className="rn-team-wrapper ptb--120 bg_color--5">
                  <div className="rn-team-area">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="section-title text-center mb--30">
                                      <h2>Our Skilled Team</h2>
                                      <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                  </div>
                              </div>
                          </div>
                          <TeamTwo column="col-lg-3" teamStyle="" item="4" />
                      </div>
                  </div>
              </div>
      {/* End Team Area--SuryAaa  */}
      
      <Footer />
    </React.Fragment>
  );
};
export default PortfolioDetails;
