import React from "react";
import ScrollToTop from "react-scroll-up";
import TextLoop from "react-text-loop";
import FooterTwo from "../component/footer/FooterTwo";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFour";
import TabThree from "../elements/tab/TabThree";
import Helmet from "../component/common/Helmet";
import ReactGA from "react-ga4";
import TeamTwo from "../blocks/team/TeamTwo.jsx";

const SlideList = [
  {
    textPosition: "text-left",
    category: "Bun Venit ",
    description: "",
    buttonText: "",
    buttonLink: ""
  }
];

ReactGA.initialize("G-BV4CKQRCZC");
ReactGA.send("Home page Visiting");

const DesignerPortfolio = () => {
  return (
    <div>
      <Helmet pageTitle="Dani Anca" />
      {/* Start Header Area  */}
      <Header headerPosition="header--static" logo="symbol-dark" color="color-black" />
      {/* End Header Area  */}

      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--5 rn-slider-height"
            key={index}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="designer-thumbnail">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/sapunmontan.appspot.com/o/casacaly%2FDani%2FfastICON.jpg?alt=media&token=1a6c2398-c6b3-4464-b4d4-e54700e15b6b"
                      alt="Slider Images"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="col-lg-7 mt_md--40 mt_sm--40">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ""}
                    <h1 className="title">
                      Salut, sunt Dani Anca <br />
                      <TextLoop>
                        <span>Fotograf</span>
                        <span>Videograf</span>
                      </TextLoop>
                    </h1>
                    <h2>din Turda/Cluj</h2>
                    {value.description ? <p className="description">{value.description}</p> : ""}
                    {value.buttonText ? (
                      <div className="slide-btn">
                        <a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>

      {/* Start Team Area--SuryAaa  */}
      <div className="rn-team-wrapper ptb--120 bg_color--5">
        <div className="rn-team-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Echipa Noastră</h2>
                  <p>
                    📸 Când râzi, plângi sau dansezi, <strong>noi suntem acolo.</strong>
                    <br /> să surprindem fiecare <strong>sentiment.</strong> <br />
                    <br />
                    Deplasări <strong>oriunde.</strong>
                  </p>
                </div>
              </div>
            </div>
            <TeamTwo column="col-lg-3" teamStyle="" item="4" />
          </div>
        </div>
      </div>
      {/* End Team Area--SuryAaa  */}

      {/* Start Designer Portfolio  */}
      <div id="projects" className="designer-portfolio-area ptb--120 bg_color--1">
        <div className="wrapper plr--70 plr_sm--30 plr_md--30">
          <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
        </div>
      </div>
      {/* End Designer Portfolio  */}

      {/* Start Footer Style  */}
      <FooterTwo />
      {/* End Footer Style  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default DesignerPortfolio;
